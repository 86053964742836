.hero h1{
    position: absolute;
    font-size: 45px;
    color: white;
    bottom: 0px;
}
div.overlay{
    position: absolute;
    bottom: 0px;
    left: 0px;
    top: 85px;
    right: 0px;
    width: 100%;
    height: calc(100vh - 85px);
    background: black;
    opacity: 0.7;
}

@media only screen and (max-width: 768px) {
    .hero h1{
        font-size: 33px;
        margin-top: 0px;
    }
}