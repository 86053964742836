@font-face {
  font-family: 'GibsonLight';
  src: url('./assets/fonts/gibson-light-webfont.woff2') format('woff2'),
      url('./assets/fonts/gibson-light-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}
@font-face {
  font-family: 'GibsonRegular';
  src: url('./assets/fonts/gibsonlight-regular-webfont.woff2') format('woff2'),
      url('./assets/fonts/gibsonlight-regular-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}
@font-face {
  font-family: 'prataregular';
  src: url('./assets/fonts/prata-regular-webfont.woff2') format('woff2'),
      url('./assets/fonts/prata-regular-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}
:root{
  --republicom-blue: #002142;
  --republicom-gold: #C19153;
  --republicom-dark-gray: #414042;
  --republicom-light-gray: #7F7F7F;
}
*{
  font-family: 'GibsonLight'; 
}
h1, h2, h3, h4, h5, h6{
  font-family: 'prataregular' !important;
  color: var(--republicom-dark-gray);
}
p{
  font-size: 17px;
}
.row-padding{
  padding-top: 70px;
  padding-bottom: 70px;
}
.row-padding-bottom{
  padding-bottom: 60px;
}
.row-padding-top{
  padding-top: 60px;
}
.double-row-padding{
  padding-top: 160px;
  padding-bottom: 100px;
}
.double-row-padding-top{
  padding-top: 160px;
  padding-bottom: 40px;
}
.double-row-padding-bottom{
  padding-bottom: 160px;
  padding-top: 40px;
}
.carousel-inner .item img{
  width: 100%;
  height: 500px;
  object-fit: cover;
  object-position: center;
}
.full-body-content{
  min-height: calc(100vh - 122px);
}
.img-full{
  width: 100%;
  height: auto;
}