.pagenotfound img{
    width: 80%;
    height: auto;
    display: block;
    margin-top: 100px;
    margin-bottom: 40px;
    margin-left: auto;
    margin-right: auto;
}

.pagenotfound p{
    font-size: 21px;
}
.pagenotfound a{
    font-size: 21px;
    border-radius: 10px;
    background: var(--republicom-blue);
    color: white !important;
    text-decoration: none;
    font-weight: bold;
}