.news h1{
    font-size: 50px;
    margin-top: 0px;
}
.news h2{
    font-size: 40px;
    margin-top: 0px;
}
.news h3{
    font-size: 27px;
    margin-top: 0px;
}
.news p{
    font-size: 15px;
}
.news .fa-minus{
    font-weight: 100 !important;
}
.news hr{
    margin: 50px 0px;
}
.news{
    margin-bottom: 130px;
}
p strong a{
    color: var(--republicom-gold);
}
.news .placeholder{
    background: var(--republicom-light-gray);
    border-radius: 50px;
    opacity: 0.2;
    margin-top: 15px;
}
.news .placeholder.full-length{
    width: 100%;
}
.news .placeholder.third-length{
    width: 65%;
}
.news .placeholder.half-length{
    width: 40%;
}
.news .placeholder.thick{
    height: 10px;
}
.news .placeholder.thin{
    height: 5px;
}

@media only screen and (max-width: 768px) {
    .news h1{
        font-size: 40px;
        margin-top: 0px;
    }
    .news h2{
        font-size: 27px;
        margin-top: 0px;
    }
    .news h3{
        font-size: 18px !important;
    }
}
