.agency-card{
    margin-bottom: 30px;
}
.agency-card h5{
    font-size: 15px;
    margin-bottom: 5px; 
    color: var(--republicom-dark-gray);
}
a .agency-card p{
    margin-bottom: 0px;
    color: var(--republicom-dark-gray);
}
a .agency-card-img{
    width: 100%;
    height: 400px;
}
a:hover .agency-card p, a:hover .agency-card h5{
    color: var(--republicom-gold);
}
.agency-subcard{
    width: calc(100% - 90px);
    -webkit-box-shadow: 0px 0px 12px 1px rgba(0,0,0,0.25);
    -moz-box-shadow: 0px 0px 12px 1px rgba(0,0,0,0.25);
    box-shadow: 0px 0px 12px 1px rgba(0,0,0,0.25);
    padding: 10px 20px;
    height: 100px;
    margin: 0 auto;
    display: block;
    margin-top: -40px;
    z-index: 400000;
    background: white !important;
}