.careers h1{
    font-size: 50px;
    margin-top: 0px;
    margin-bottom: 20px;
}
.careers h2{
    font-size: 40px;
    margin-top: 0px;
}
.careers p{
    font-size: 17px;
}
.careers .perk-icon{
    width: 40px;
    margin: 0 auto;
    display: block;
    margin-bottom: 10px;
}
.careers .perk-div{
    height: 150px;
}
.available-positions{
    text-transform: uppercase;
    font-weight: bolder;
}
.available-positions a{
    color: black;
}
.working-with-us{
    height: 400px;
    margin-bottom: 30px;
    overflow: hidden;
    display: flex
}
.working-with-us .center-text{
    margin:auto;
}
.careers .job-title{
    background: white;
    border: none;
    padding: 5px 0px;
    width: 100%;
    text-align: left;
    outline: none;
}
.careers .job-description{
    margin-bottom: 10px;
    color: rgb(54, 54, 54);
    padding: 0px 20px;
}
.careers .job-opportunity{
    border-bottom: 1px solid rgb(230, 230, 230);
}

.careers .job-opportunity .fa-chevron-right{
    font-size: 20px;
}
h3 span{
    font-size: 17px;
}
p.more a{
    font-weight: bolder;
    text-decoration: none;
    color: black;
}

@media only screen and (max-width: 768px) {
    .careers h1{
        font-size: 40px;
        margin-top: 0px;
    }
    .careers h2{
        font-size: 27px;
        margin-top: 0px;
    }
    .careers h3{
        font-size: 18px !important;
    }
}

@media only screen and (max-width: 500px) {
    .careers .perk-div{
        height: 160px;
    }
    .careers .perk-div p{
        font-size: 10px !important;
    }
}
