.republicomcollege h1{
    font-size: 50px;
    margin-top: 0px;
    margin-bottom: 20px;
}
.republicomcollege h2{
    font-size: 40px;
    margin-top: 0px;
}
.republicomcollege p{
    font-size: 15px;
}
.republicomcollege p.year{
    font-size: 18px;
    margin-top: 22px;
}
.republicomcollege .event-title{
    background: white;
    border: none;
    padding: 5px 0px;
    width: 100%;
    text-align: left;
    outline: none;
}
.republicomcollege .event-description{
    margin-bottom: 10px;
}
.republicomcollege .event-body{
    border-bottom: 1px solid rgb(230, 230, 230);
}

.republicomcollege .event-body .fa-chevron-right{
    font-size: 20px;
}

@media only screen and (max-width: 768px) {
    .republicomcollege h1{
        font-size: 40px;
        margin-top: 0px;
    }
    .republicomcollege h2{
        font-size: 27px;
        margin-top: 0px;
    }
    .republicomcollege h3{
        font-size: 18px !important;
    }
}