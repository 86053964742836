body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: 'GibsonLight';
  src: url(/static/media/gibson-light-webfont.593f2aee.woff2) format('woff2'),
      url(/static/media/gibson-light-webfont.ab940d62.woff) format('woff');
  font-weight: normal;
  font-style: normal;

}
@font-face {
  font-family: 'GibsonRegular';
  src: url(/static/media/gibsonlight-regular-webfont.8e1801e4.woff2) format('woff2'),
      url(/static/media/gibsonlight-regular-webfont.b6259044.woff) format('woff');
  font-weight: normal;
  font-style: normal;

}
@font-face {
  font-family: 'prataregular';
  src: url(/static/media/prata-regular-webfont.67adf4ae.woff2) format('woff2'),
      url(/static/media/prata-regular-webfont.ce513a6a.woff) format('woff');
  font-weight: normal;
  font-style: normal;

}
:root{
  --republicom-blue: #002142;
  --republicom-gold: #C19153;
  --republicom-dark-gray: #414042;
  --republicom-light-gray: #7F7F7F;
}
*{
  font-family: 'GibsonLight'; 
}
h1, h2, h3, h4, h5, h6{
  font-family: 'prataregular' !important;
  color: #414042;
  color: var(--republicom-dark-gray);
}
p{
  font-size: 17px;
}
.row-padding{
  padding-top: 70px;
  padding-bottom: 70px;
}
.row-padding-bottom{
  padding-bottom: 60px;
}
.row-padding-top{
  padding-top: 60px;
}
.double-row-padding{
  padding-top: 160px;
  padding-bottom: 100px;
}
.double-row-padding-top{
  padding-top: 160px;
  padding-bottom: 40px;
}
.double-row-padding-bottom{
  padding-bottom: 160px;
  padding-top: 40px;
}
.carousel-inner .item img{
  width: 100%;
  height: 500px;
  object-fit: cover;
  object-position: center;
}
.full-body-content{
  min-height: calc(100vh - 122px);
}
.img-full{
  width: 100%;
  height: auto;
}
header{
    background: var(--republicom-blue);
    padding: 10px 0px;
    z-index: 300;
}
header *{color: var(--republicom-gold);}
header .logo{height: 65px;}
header i{padding: 20px 0px; cursor: pointer; font-size: 22px;}
.modal-dialog{
    width: 100vw !important;
}
.modal-content{
    background: transparent !important;
    border: 0px !important;
    box-shadow: none !important;
}
.modal-header{
    border-bottom: none;
    padding: 0px 15px;
}
.modal-backdrop.in{
    opacity: 0.95;
}
.modal-backdrop{
    background: var(--republicom-blue);
}
.modal-body{
    padding: 0px 15px;
}
h2.navigation-link{
    color: white;
}
h2.navigation-link.true{
    color: var(--republicom-gold); 
}
h2.navigation-link:hover{
   cursor: pointer;
   color: var(--republicom-gold);  
}
button.close{
    font-size: 40px;
    padding-right: 8%;
    opacity: 1;
    color: white !important;
    font-weight: 100;
    outline: none !important;
}
.sidebar-nav{
    background: black;
    width: 60px;
    height: 60px;
    line-height: 60px;
    text-align: center;
    position: fixed;
    right: 0px;
    bottom: 80px;
    cursor: pointer;
    z-index: 99999;
}
.sidebar-nav i{
    color: white;
}

.footer{
    background: var(--republicom-blue);
    padding-top: 15px;
    padding-bottom: 15px;
}
.footer p{
    color: white;
    margin-bottom: 0px;
    font-size: 12px;
}
.footer .social-icons a{
    color: white;
    margin-right: 10px;
    text-decoration: none;
}
@-webkit-keyframes fadeIn {
    from { opacity: 0; }
      to { opacity: 1; }
}  
@keyframes fadeIn {
    from { opacity: 0; }
      to { opacity: 1; }
}
.carousel-inner .item img{
    height: auto !important;
}
.home h2{
    font-size: 40px;
    margin-top: 0px;
}
.home h3{
    font-size: 27px;
}
.home .service-description{
    height: 90px;
}
.home .company-logo{
    width: 100%;
    height: auto;
    margin-bottom: 20px;
    padding: 20px;
    -webkit-filter: grayscale(100%);
    filter: grayscale(100%);
}
.home .company-logo:hover{
    cursor: pointer;
    -webkit-filter: grayscale(0%);
    filter: grayscale(0%);
}
li.nav-item.active a{
    border: none;
    border-bottom: 3px solid var(--republicom-gold) !important;
}
li.nav-item.active{
    border: none !important;
}
.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-item{
    border-bottom: 1px solid var(--republicom-gold);
}
a.nav-link{
    color: var(--republicom-dark-gray) !important;
}
a.nav-link.active, a.nav-link:hover{
    color: var(--republicom-gold) !important;
    border: none;
}
.nav-tabs.nav-justified>.active>a, 
.nav-tabs.nav-justified>.active>a:focus, 
.nav-tabs.nav-justified>.active>a:hover{
    border: none; 
}
.nav-tabs.nav-justified>.nav-item>a, 
.nav-tabs.nav-justified>.nav-item>a:focus, 
.nav-tabs.nav-justified>.nav-item>a:hover{
    background: white; 
}
.nav-tabs.nav-justified>li>a{
    border-bottom: none;
}
.platform-logo{
    width: 100px;
    display: block;
    float: right;
}
.platform-logo-rc{
    width: 130px;
    display: block;
    margin-bottom: 10px;
    float: right;
}
.platform-site{
    display: block;
    float: right;
    clear: both;
    text-decoration: underline;
}
.platform-site a{color: var(--republicom-dark-gray);}
.platform-social-media{
    display: block;
    float: right;
    clear: both;
}
.platform-social-media a{
    text-decoration: none;
    margin-left: 10px;
    color: white;
    background: var(--republicom-dark-gray);
    width: 30px;
    height: 30px;
    line-height: 30px;
    display: inline-block;
    text-align: center;
    border-radius: 50%;
}
.home .services{
    height: 330px; 
    width: '100%';  
    padding: 30px; 
}
.home .home-scroll-down{
    cursor: pointer;
}
.home .services p, .home .services h4{
    color: white;
}
.home .services p{
    font-size: 17px;
}
.home .services p, .home .services h4{
    font-size: 20px;
}
.home .services.communication{
    background: url(/static/media/home_communication.910a9d68.jpg);
    background-position: center;
    background-repeat: no-repeat; 
    background-size: cover;
}
.home .services.communication p{
    display: none;
}
.home .services.communication:hover p{
    display: block;
    -webkit-animation: fadeIn 2s;
    animation: fadeIn 2s;
}
.home .services.consulting{
    background: url(/static/media/home_consulting.87db50f0.jpg);
    background-position: center;
    background-repeat: no-repeat; 
    background-size: cover;
}
.home .services.consulting p{display: none;}
.home .services.consulting:hover p{
    display: block;
    -webkit-animation: fadeIn 2s;
    animation: fadeIn 2s;
}
.home .services.productsandservices{
    background: url(/static/media/home_productsandservices.3daa2d55.jpg);
    background-position: center;
    background-repeat: no-repeat; 
    background-size: cover;
}
.home .services.productsandservices p{display: none;}
.home .services.productsandservices:hover p{
    display: block;
    -webkit-animation: fadeIn 2s;
    animation: fadeIn 2s;
}
.home .services.sportsmarketing{
    background: url(/static/media/home_sportsmarketing.9804d2d7.jpg);
    background-position: center;
    background-repeat: no-repeat; 
    background-size: cover;
}
.home .services.sportsmarketing p{display: none;}
.home .services.sportsmarketing:hover p{
    display: block;
    -webkit-animation: fadeIn 2s;
    animation: fadeIn 2s;
}
.home .services.studios{
    background: url(/static/media/home_studios.30d83458.jpg);
    background-position: center;
    background-repeat: no-repeat; 
    background-size: cover;
}
.home .services.studios p{display: none;}
.home .services.studios:hover p{
    display: block;
    -webkit-animation: fadeIn 2s;
    animation: fadeIn 2s;
}
.home .services.ventures{
    background: url(/static/media/home_ventures.bf24e341.jpg);
    background-position: center;
    background-repeat: no-repeat; 
    background-size: cover;
}
.home .services.ventures p{display: none;}
.home .services.ventures:hover p{
    display: block;
    -webkit-animation: fadeIn 2s;
    animation: fadeIn 2s;
}

@media only screen and (max-width: 768px) {
    .home h1{
        font-size: 40px;
        margin-top: 0px;
    }
    .home h2{
        font-size: 27px;
        margin-top: 0px;
    }
    .home h3{
        font-size: 18px !important;
    }
}

.hero h1{
    position: absolute;
    font-size: 45px;
    color: white;
    bottom: 0px;
}
div.overlay{
    position: absolute;
    bottom: 0px;
    left: 0px;
    top: 85px;
    right: 0px;
    width: 100%;
    height: calc(100vh - 85px);
    background: black;
    opacity: 0.7;
}

@media only screen and (max-width: 768px) {
    .hero h1{
        font-size: 33px;
        margin-top: 0px;
    }
}
.square-image-div{
    width: 100%;
    padding-top: 100%;
}
.about h1{
    font-size: 50px;
    margin-top: 0px;
}
.about h2{
    font-size: 40px;
    margin-top: 0px;
}
.about p{
    font-size: 17px;
}

/* Owl Carousel */
.about .item{
    height: 350px;
}
.about .owl-carousel .owl-nav button.owl-prev, 
.about .owl-carousel .owl-nav button.owl-next, 
.about .owl-carousel button.owl-dot{
    background: white !important;
    color: black !important;
    outline: none !important;
    margin-top: 0px;
}
.about .owl-carousel .owl-nav button.owl-next{
    margin-left: 40px;
}
.about .value-illustration{
    max-height: 320px;
    float: right;
    width: auto;
}
.about .owl-nav{
    float: left;
    margin-top: 0px;
    font-size: 80px;
    line-height: 0px;
    padding: 0px;
}
.about .owl-dots{
    display: none;
}

@media only screen and (max-width: 768px) {
    .about h1{
        font-size: 40px;
        margin-top: 0px;
    }
    .about h2{
        font-size: 27px;
        margin-top: 0px;
    }
    .about h3{
        font-size: 18px !important;
    }
    .about .value-illustration{
        float: left !important;
    }
    .owl-carousel .owl-item img{
        width: 280px !important;
    }
}
.agency-card{
    margin-bottom: 30px;
}
.agency-card h5{
    font-size: 15px;
    margin-bottom: 5px; 
    color: var(--republicom-dark-gray);
}
a .agency-card p{
    margin-bottom: 0px;
    color: var(--republicom-dark-gray);
}
a .agency-card-img{
    width: 100%;
    height: 400px;
}
a:hover .agency-card p, a:hover .agency-card h5{
    color: var(--republicom-gold);
}
.agency-subcard{
    width: calc(100% - 90px);
    box-shadow: 0px 0px 12px 1px rgba(0,0,0,0.25);
    padding: 10px 20px;
    height: 100px;
    margin: 0 auto;
    display: block;
    margin-top: -40px;
    z-index: 400000;
    background: white !important;
}
.careers h1{
    font-size: 50px;
    margin-top: 0px;
    margin-bottom: 20px;
}
.careers h2{
    font-size: 40px;
    margin-top: 0px;
}
.careers p{
    font-size: 17px;
}
.careers .perk-icon{
    width: 40px;
    margin: 0 auto;
    display: block;
    margin-bottom: 10px;
}
.careers .perk-div{
    height: 150px;
}
.available-positions{
    text-transform: uppercase;
    font-weight: bolder;
}
.available-positions a{
    color: black;
}
.working-with-us{
    height: 400px;
    margin-bottom: 30px;
    overflow: hidden;
    display: flex
}
.working-with-us .center-text{
    margin:auto;
}
.careers .job-title{
    background: white;
    border: none;
    padding: 5px 0px;
    width: 100%;
    text-align: left;
    outline: none;
}
.careers .job-description{
    margin-bottom: 10px;
    color: rgb(54, 54, 54);
    padding: 0px 20px;
}
.careers .job-opportunity{
    border-bottom: 1px solid rgb(230, 230, 230);
}

.careers .job-opportunity .fa-chevron-right{
    font-size: 20px;
}
h3 span{
    font-size: 17px;
}
p.more a{
    font-weight: bolder;
    text-decoration: none;
    color: black;
}

@media only screen and (max-width: 768px) {
    .careers h1{
        font-size: 40px;
        margin-top: 0px;
    }
    .careers h2{
        font-size: 27px;
        margin-top: 0px;
    }
    .careers h3{
        font-size: 18px !important;
    }
}

@media only screen and (max-width: 500px) {
    .careers .perk-div{
        height: 160px;
    }
    .careers .perk-div p{
        font-size: 10px !important;
    }
}

.people h1{
    font-size: 50px;
    margin-top: 0px;
    margin-bottom: 20px;
}
.people h2{
    font-size: 40px;
    margin-top: 0px;
}
.people p{
    font-size: 17px;
}
.people .img-square{
    width: 100%;
}
.people .team-leads{
    height: 383px;
    overflow: hidden;
    margin-bottom: 40px;
    margin-top: 20px;
}
.people .team-leads-2{
    margin-top: 20px;
    margin-bottom: 70px;
}
.people .team-leads-2 p{
    margin-bottom: 5px;
}
.people .team-leads-2 h3{
    margin-top: 0px;
    margin-bottom: 5px;
}
.people .work-with-us p a{
    color: black;
    text-transform: uppercase;
    font-weight: bolder;
}

@media only screen and (max-width: 768px) {
    .people h1{
        font-size: 40px;
        margin-top: 0px;
    }
    .people h2{
        font-size: 27px;
        margin-top: 0px;
    }
    .people .team-leads{
        height: auto;
        overflow: auto;
    }
}

@media only screen and (max-width: 1200px) {
    .people .team-leads{
        height: 430px;
        overflow: hidden;
    }
}

@media only screen and (max-width: 998px) {
    .people .team-leads{
        height: 550px;
        overflow: hidden;
    }
    .people .team-leads{margin-bottom: 10px;}
}
.news h1{
    font-size: 50px;
    margin-top: 0px;
}
.news h2{
    font-size: 40px;
    margin-top: 0px;
}
.news h3{
    font-size: 27px;
    margin-top: 0px;
}
.news p{
    font-size: 15px;
}
.news .fa-minus{
    font-weight: 100 !important;
}
.news hr{
    margin: 50px 0px;
}
.news{
    margin-bottom: 130px;
}
p strong a{
    color: var(--republicom-gold);
}
.news .placeholder{
    background: var(--republicom-light-gray);
    border-radius: 50px;
    opacity: 0.2;
    margin-top: 15px;
}
.news .placeholder.full-length{
    width: 100%;
}
.news .placeholder.third-length{
    width: 65%;
}
.news .placeholder.half-length{
    width: 40%;
}
.news .placeholder.thick{
    height: 10px;
}
.news .placeholder.thin{
    height: 5px;
}

@media only screen and (max-width: 768px) {
    .news h1{
        font-size: 40px;
        margin-top: 0px;
    }
    .news h2{
        font-size: 27px;
        margin-top: 0px;
    }
    .news h3{
        font-size: 18px !important;
    }
}

.contact h1{
    font-size: 50px;
    margin-top: 0px;
}
.contact p{
    font-size: 17px;
}
.contact input, .contact textarea{
    outline: none !important;
    box-shadow: none !important;
    border-radius: 0px;
}
.contact input:focus, .contact textarea:focus{
    border-color: var(--republicom-blue);
}
.contact form{
    margin-bottom: 10px;
}
.contact .btn-submit{
    background: var(--republicom-dark-gray);
    color: white;
    font-weight: bolder;
    font-size: 18px;
    border-radius: 0px;
    height: auto;
    text-transform: uppercase;
    outline: none !important;
    box-shadow: none !important;
}
.contact .btn-submit[disabled]{
    background: var(--republicom-light-gray);
}
.contact .address{
    margin-top: 40px;
    margin-bottom: 60px;
}
.contact .address a{
    color: var(--republicom-dark-gray);
    text-decoration: none;
}

@media only screen and (max-width: 768px) {
    .contact h1{
        font-size: 40px;
        margin-top: 0px;
    }
}
.republicomcollege h1{
    font-size: 50px;
    margin-top: 0px;
    margin-bottom: 20px;
}
.republicomcollege h2{
    font-size: 40px;
    margin-top: 0px;
}
.republicomcollege p{
    font-size: 15px;
}
.republicomcollege p.year{
    font-size: 18px;
    margin-top: 22px;
}
.republicomcollege .event-title{
    background: white;
    border: none;
    padding: 5px 0px;
    width: 100%;
    text-align: left;
    outline: none;
}
.republicomcollege .event-description{
    margin-bottom: 10px;
}
.republicomcollege .event-body{
    border-bottom: 1px solid rgb(230, 230, 230);
}

.republicomcollege .event-body .fa-chevron-right{
    font-size: 20px;
}

@media only screen and (max-width: 768px) {
    .republicomcollege h1{
        font-size: 40px;
        margin-top: 0px;
    }
    .republicomcollege h2{
        font-size: 27px;
        margin-top: 0px;
    }
    .republicomcollege h3{
        font-size: 18px !important;
    }
}
.pagenotfound img{
    width: 80%;
    height: auto;
    display: block;
    margin-top: 100px;
    margin-bottom: 40px;
    margin-left: auto;
    margin-right: auto;
}

.pagenotfound p{
    font-size: 21px;
}
.pagenotfound a{
    font-size: 21px;
    border-radius: 10px;
    background: var(--republicom-blue);
    color: white !important;
    text-decoration: none;
    font-weight: bold;
}
