@-webkit-keyframes fadeIn {
    from { opacity: 0; }
      to { opacity: 1; }
}  
@keyframes fadeIn {
    from { opacity: 0; }
      to { opacity: 1; }
}
.carousel-inner .item img{
    height: auto !important;
}
.home h2{
    font-size: 40px;
    margin-top: 0px;
}
.home h3{
    font-size: 27px;
}
.home .service-description{
    height: 90px;
}
.home .company-logo{
    width: 100%;
    height: auto;
    margin-bottom: 20px;
    padding: 20px;
    -webkit-filter: grayscale(100%);
    filter: grayscale(100%);
}
.home .company-logo:hover{
    cursor: pointer;
    -webkit-filter: grayscale(0%);
    filter: grayscale(0%);
}
li.nav-item.active a{
    border: none;
    border-bottom: 3px solid var(--republicom-gold) !important;
}
li.nav-item.active{
    border: none !important;
}
.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-item{
    border-bottom: 1px solid var(--republicom-gold);
}
a.nav-link{
    color: var(--republicom-dark-gray) !important;
}
a.nav-link.active, a.nav-link:hover{
    color: var(--republicom-gold) !important;
    border: none;
}
.nav-tabs.nav-justified>.active>a, 
.nav-tabs.nav-justified>.active>a:focus, 
.nav-tabs.nav-justified>.active>a:hover{
    border: none; 
}
.nav-tabs.nav-justified>.nav-item>a, 
.nav-tabs.nav-justified>.nav-item>a:focus, 
.nav-tabs.nav-justified>.nav-item>a:hover{
    background: white; 
}
.nav-tabs.nav-justified>li>a{
    border-bottom: none;
}
.platform-logo{
    width: 100px;
    display: block;
    float: right;
}
.platform-logo-rc{
    width: 130px;
    display: block;
    margin-bottom: 10px;
    float: right;
}
.platform-site{
    display: block;
    float: right;
    clear: both;
    text-decoration: underline;
}
.platform-site a{color: var(--republicom-dark-gray);}
.platform-social-media{
    display: block;
    float: right;
    clear: both;
}
.platform-social-media a{
    text-decoration: none;
    margin-left: 10px;
    color: white;
    background: var(--republicom-dark-gray);
    width: 30px;
    height: 30px;
    line-height: 30px;
    display: inline-block;
    text-align: center;
    border-radius: 50%;
}
.home .services{
    height: 330px; 
    width: '100%';  
    padding: 30px; 
}
.home .home-scroll-down{
    cursor: pointer;
}
.home .services p, .home .services h4{
    color: white;
}
.home .services p{
    font-size: 17px;
}
.home .services p, .home .services h4{
    font-size: 20px;
}
.home .services.communication{
    background: url('../../assets/images/home_communication.jpg');
    background-position: center;
    background-repeat: no-repeat; 
    background-size: cover;
}
.home .services.communication p{
    display: none;
}
.home .services.communication:hover p{
    display: block;
    -webkit-animation: fadeIn 2s;
    animation: fadeIn 2s;
}
.home .services.consulting{
    background: url('../../assets/images/home_consulting.jpg');
    background-position: center;
    background-repeat: no-repeat; 
    background-size: cover;
}
.home .services.consulting p{display: none;}
.home .services.consulting:hover p{
    display: block;
    -webkit-animation: fadeIn 2s;
    animation: fadeIn 2s;
}
.home .services.productsandservices{
    background: url('../../assets/images/home_productsandservices.jpg');
    background-position: center;
    background-repeat: no-repeat; 
    background-size: cover;
}
.home .services.productsandservices p{display: none;}
.home .services.productsandservices:hover p{
    display: block;
    -webkit-animation: fadeIn 2s;
    animation: fadeIn 2s;
}
.home .services.sportsmarketing{
    background: url('../../assets/images/home_sportsmarketing.jpg');
    background-position: center;
    background-repeat: no-repeat; 
    background-size: cover;
}
.home .services.sportsmarketing p{display: none;}
.home .services.sportsmarketing:hover p{
    display: block;
    -webkit-animation: fadeIn 2s;
    animation: fadeIn 2s;
}
.home .services.studios{
    background: url('../../assets/images/home_studios.jpg');
    background-position: center;
    background-repeat: no-repeat; 
    background-size: cover;
}
.home .services.studios p{display: none;}
.home .services.studios:hover p{
    display: block;
    -webkit-animation: fadeIn 2s;
    animation: fadeIn 2s;
}
.home .services.ventures{
    background: url('../../assets/images/home_ventures.jpg');
    background-position: center;
    background-repeat: no-repeat; 
    background-size: cover;
}
.home .services.ventures p{display: none;}
.home .services.ventures:hover p{
    display: block;
    -webkit-animation: fadeIn 2s;
    animation: fadeIn 2s;
}

@media only screen and (max-width: 768px) {
    .home h1{
        font-size: 40px;
        margin-top: 0px;
    }
    .home h2{
        font-size: 27px;
        margin-top: 0px;
    }
    .home h3{
        font-size: 18px !important;
    }
}
