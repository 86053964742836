.footer{
    background: var(--republicom-blue);
    padding-top: 15px;
    padding-bottom: 15px;
}
.footer p{
    color: white;
    margin-bottom: 0px;
    font-size: 12px;
}
.footer .social-icons a{
    color: white;
    margin-right: 10px;
    text-decoration: none;
}