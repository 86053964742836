.people h1{
    font-size: 50px;
    margin-top: 0px;
    margin-bottom: 20px;
}
.people h2{
    font-size: 40px;
    margin-top: 0px;
}
.people p{
    font-size: 17px;
}
.people .img-square{
    width: 100%;
}
.people .team-leads{
    height: 383px;
    overflow: hidden;
    margin-bottom: 40px;
    margin-top: 20px;
}
.people .team-leads-2{
    margin-top: 20px;
    margin-bottom: 70px;
}
.people .team-leads-2 p{
    margin-bottom: 5px;
}
.people .team-leads-2 h3{
    margin-top: 0px;
    margin-bottom: 5px;
}
.people .work-with-us p a{
    color: black;
    text-transform: uppercase;
    font-weight: bolder;
}

@media only screen and (max-width: 768px) {
    .people h1{
        font-size: 40px;
        margin-top: 0px;
    }
    .people h2{
        font-size: 27px;
        margin-top: 0px;
    }
    .people .team-leads{
        height: auto;
        overflow: auto;
    }
}

@media only screen and (max-width: 1200px) {
    .people .team-leads{
        height: 430px;
        overflow: hidden;
    }
}

@media only screen and (max-width: 998px) {
    .people .team-leads{
        height: 550px;
        overflow: hidden;
    }
    .people .team-leads{margin-bottom: 10px;}
}