header{
    background: var(--republicom-blue);
    padding: 10px 0px;
    z-index: 300;
}
header *{color: var(--republicom-gold);}
header .logo{height: 65px;}
header i{padding: 20px 0px; cursor: pointer; font-size: 22px;}
.modal-dialog{
    width: 100vw !important;
}
.modal-content{
    background: transparent !important;
    border: 0px !important;
    box-shadow: none !important;
}
.modal-header{
    border-bottom: none;
    padding: 0px 15px;
}
.modal-backdrop.in{
    opacity: 0.95;
}
.modal-backdrop{
    background: var(--republicom-blue);
}
.modal-body{
    padding: 0px 15px;
}
h2.navigation-link{
    color: white;
}
h2.navigation-link.true{
    color: var(--republicom-gold); 
}
h2.navigation-link:hover{
   cursor: pointer;
   color: var(--republicom-gold);  
}
button.close{
    font-size: 40px;
    padding-right: 8%;
    opacity: 1;
    color: white !important;
    font-weight: 100;
    outline: none !important;
}
.sidebar-nav{
    background: black;
    width: 60px;
    height: 60px;
    line-height: 60px;
    text-align: center;
    position: fixed;
    right: 0px;
    bottom: 80px;
    cursor: pointer;
    z-index: 99999;
}
.sidebar-nav i{
    color: white;
}
