.about h1{
    font-size: 50px;
    margin-top: 0px;
}
.about h2{
    font-size: 40px;
    margin-top: 0px;
}
.about p{
    font-size: 17px;
}

/* Owl Carousel */
.about .item{
    height: 350px;
}
.about .owl-carousel .owl-nav button.owl-prev, 
.about .owl-carousel .owl-nav button.owl-next, 
.about .owl-carousel button.owl-dot{
    background: white !important;
    color: black !important;
    outline: none !important;
    margin-top: 0px;
}
.about .owl-carousel .owl-nav button.owl-next{
    margin-left: 40px;
}
.about .value-illustration{
    max-height: 320px;
    float: right;
    width: auto;
}
.about .owl-nav{
    float: left;
    margin-top: 0px;
    font-size: 80px;
    line-height: 0px;
    padding: 0px;
}
.about .owl-dots{
    display: none;
}

@media only screen and (max-width: 768px) {
    .about h1{
        font-size: 40px;
        margin-top: 0px;
    }
    .about h2{
        font-size: 27px;
        margin-top: 0px;
    }
    .about h3{
        font-size: 18px !important;
    }
    .about .value-illustration{
        float: left !important;
    }
    .owl-carousel .owl-item img{
        width: 280px !important;
    }
}