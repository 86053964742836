.contact h1{
    font-size: 50px;
    margin-top: 0px;
}
.contact p{
    font-size: 17px;
}
.contact input, .contact textarea{
    outline: none !important;
    box-shadow: none !important;
    border-radius: 0px;
}
.contact input:focus, .contact textarea:focus{
    border-color: var(--republicom-blue);
}
.contact form{
    margin-bottom: 10px;
}
.contact .btn-submit{
    background: var(--republicom-dark-gray);
    color: white;
    font-weight: bolder;
    font-size: 18px;
    border-radius: 0px;
    height: auto;
    text-transform: uppercase;
    outline: none !important;
    box-shadow: none !important;
}
.contact .btn-submit[disabled]{
    background: var(--republicom-light-gray);
}
.contact .address{
    margin-top: 40px;
    margin-bottom: 60px;
}
.contact .address a{
    color: var(--republicom-dark-gray);
    text-decoration: none;
}

@media only screen and (max-width: 768px) {
    .contact h1{
        font-size: 40px;
        margin-top: 0px;
    }
}